import styled, { css } from 'styled-components';

import { device } from 'components/device';

export const BrandingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(-170px + 24px);

  gap: 48px;

  img {
    height: auto;
    :first-child {
      width: 10.625rem;
      @media ${device.mobile} {
        width: 9.063rem;
        margin-left: 24px;
      }
    }
  }
`;

export const HeaderWrapper = styled.div`
  width: 100vw;
  grid-area: HD;
`;
