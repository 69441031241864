import React, { useEffect, useContext, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';

import { DSLandingFeaturedMarketing } from 'components/CmsComponents/DSLandingFeaturedMarketing';

import Components from 'components/CmsComponents/';

import { TmpHeaderBase } from '@tempo/tempo-design-system-core/dist';

import { BrandingWrapper, HeaderWrapper } from './style';

import { HeaderContext } from 'context/header';

import Logo from 'images/logos/logo_hsl.svg';

const VivaSeuMelhor = ({
  data: {
    gcms: { vivaSeuMelhors },
  },
  pageContext,
}) => {
  const [isDesktop, setDesktop] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);
  const page = vivaSeuMelhors?.[0];
  const DSLandingFeaturedMarketingContent = page?.dSLandingFeaturedMarketing;
  const components = page?.components ?? [];
  const isWindow = typeof window !== 'undefined';

  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  const HeaderReplace = () => {
    return (
      <HeaderWrapper>
        {isWindow && { TmpHeaderBase } && (
          <TmpHeaderBase
            children={
              <BrandingWrapper>
                {page?.mostrarLogoSirio && (
                  <img src={Logo} alt="Sírio-Libanês" />
                )}
                <img src={page?.partnerLogo?.url} alt="" />
              </BrandingWrapper>
            }
          />
        )}
      </HeaderWrapper>
    );
  };

  const bot = page?.ativarBot;
  bot ? 'script' : console.log(bot);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('0rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }

    if (page?.ativarBot && window !== 'undefined') {
      const script = document.createElement('script');
      script.src = `${process.env.REACT_APP_BOT_SRC}`;
      script.addEventListener('load', () => {
        new BlipChat()
          .withAppKey(`${process.env.REACT_APP_BOT_APP_KEY}`)
          .withButton({"color":"#2879bd","icon":"https://blipmediastore.blob.core.windows.net/public-medias/Media_00831bc7-eec4-4c5b-8749-28a72ad77c56"})
          .withCustomCommonUrl(`${process.env.REACT_APP_BOT_CUSTOM_COMMON_URL}`)
          .build();
      });
      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      <Layout hideHeader={true} headerReplace={<HeaderReplace />}>
        <SEO
          dataSeo={dataSeo}
          image={DSLandingFeaturedMarketingContent?.images?.[0]?.url ?? ''}
        />

        {DSLandingFeaturedMarketingContent && (
          <DSLandingFeaturedMarketing
            content={DSLandingFeaturedMarketingContent}
          />
        )}
        {components.length >= 1 &&
          components?.map(component => {
            return Components(component);
          })}
      </Layout>
    </>
  );
};

export const VivaSeuMelhorPageQuery = graphql`
  query VivaSeuMelhorPageQuery($id: ID) {
    gcms {
      vivaSeuMelhors(locales: [pt_BR, en], where: { id: $id }) {
        id
        title
        slug
        seo {
          id
          metaTitle
          metaDescription
          noIndex
        }
        ativarBot
        partnerLogo {
          height
          handle
          url
          width
        }
        mostrarLogoSirio
        dSLandingFeaturedMarketing(locales: [pt_BR, en]) {
          id
          type
          universalLink
          activeBreaknews
          breaknews {
            id
            name
            type
            singletexts
            markdown
          }
          backgroundColor {
            hex
          }
          images {
            handle
            width
            height
            url
          }
          title
          fragments {
            id
            name
            type
            markdown
          }
        }
        components {
          ... on GraphCMS_ListPostContent {
            id
            name
            isDesignSystem
            type
            title
            headerLinkTitle
            headerLink
            posts {
              id
              slugPost
              title
              topic
              author
              doctors(first: 1000, locales: [pt_BR, en]) {
                id
                id_api
                active
                assignment
                name
              }
              assetpicker {
                handle
                height
                width
                url
              }
              categories {
                tag
              }
            }
          }
          ... on GraphCMS_DsCardsContent {
            id
            title
            description
            cards
            backgroundColor {
              hex
            }
          }
          ... on GraphCMS_DSLandingScheduling {
            id
            title
            universalLink
            backgroundColor {
              css
            }
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_DSLandingSchedulingCards {
            id
            title
            backgroundColor {
              css
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_ListQAContent {
            id
            name
            title
            type
            isDesignSystem

            anchorTo
            faqLink
            backgroundColor {
              hex
            }
            qaData {
              ... on GraphCMS_CustomFragment {
                id
                name
                markdown
                singletexts
              }
              ... on GraphCMS_Qa {
                id
                autor
                pergunta
                titulo
                doctors(first: 1000) {
                  id
                  id_api
                  active
                  assignment
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default VivaSeuMelhor;
